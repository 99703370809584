<script>
export default {
    wrong_password: `La password inserita non è corretta.`,
    email_not_verified: `L'indirizzo email non è ancora verificato. Se non hai ricevuto l'email, clicca qui per inviarla nuovamente.`,
    model_saved: `Modello salvato con successo.`,
    entry_Saved: `Voce salvata con successo.`,
    generic_error: `Si è verificato un errore. Per favore riprova più tardi.`,
    validation: {
        choose_Wallet: 'Per favore scegli un account del portafoglio',
        choose_Category: 'Per favore scegli una categoria',
        insert_amount: 'Per favore inserisci un valore per l\'importo',
        choose_Wallet_transfer: 'Per favore scegli un portafoglio diverso per il trasferimento',
        insert_payee: 'Per favore inserisci un nome del beneficiario',
        end_date_greater_than_start_date: 'La data di fine deve essere maggiore della data di inizio',
        choose_start_date: 'Per favore scegli una data di inizio',
        end_date_greater_than_today: 'La data di fine deve essere maggiore di oggi',
        start_date_greater_than_today: 'La data di inizio deve essere maggiore di oggi',
        insert_model_name: 'Per favore inserisci un nome del modello',
    },
    login: {
        not_verified_email: `L'indirizzo email non è ancora verificato. Se non hai ricevuto l'email, clicca qui per inviarla nuovamente.`,
        not_valid_password: `La password inserita non è corretta.`,
    },
    wallet: {
        are_you_sure: 'Sei sicuro di voler archiviare questo portafoglio?',
        archived: 'Portafoglio archiviato',
        restored: 'Portafoglio ripristinato',
        saved: 'Portafoglio salvato',
        wrong_Wallet: 'Per favore scegli un tipo di portafoglio corretto',
        wallet_name: 'Per favore inserisci un nome per il portafoglio',
        wallet_currency: 'Per favore scegli una valuta per il portafoglio',
        invoice_date: 'Per favore scegli una data di fatturazione',
        closing_date: 'Per favore scegli una data di chiusura',
        payment_account: 'Per favore scegli un conto di pagamento',
        installment: 'Per favore scegli un valore di rata',
        voucher_value: 'Per favore inserisci un valore del buono',
    },
    workspace: {
        updated: 'Spazio di lavoro aggiornato',
        added: 'Spazio di lavoro aggiunto',
        user_not_fount: 'Utente non trovato'
    },
    search: {
        no_results: 'Nessun risultato trovato',
        please_set_date: 'Scegli una data di inizio e fine',
    },
    delete_entry: 'Sei sicuro di voler eliminare questa voce?',
    archive_debit: 'Sei sicuro di voler archiviare questo debito?',
    we_are_loading_your_data: 'Stiamo caricando i tuoi dati...',
    install: 'Installa la nostra app dal Play Store!',
    offline: 'Sei offline. Per favore controlla la tua connessione internet.',
    chart: {
        no_data: 'Nessun dato da visualizzare',
        no_data_selected: 'Nessun dato selezionato',
    }
}
</script>