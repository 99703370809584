import { defineStore } from 'pinia';
const STORE_NAME = 'auth-token';
export const useAuthStore = defineStore(STORE_NAME, {
    state: () => ({
        authToken: {},
        bcAuthToken: {}
    }),
    actions: {
        get() {
            return {
                authToken: this.authToken,
                bcAuthToken: this.bcAuthToken
            };
        },
        resetState() {
            this.$reset();
        }
    },
    persist: true,
});
