<script>
export default {
    sign_in_with: 'Iniciar sesión con',
    or_sign_in_with_credentials: 'O iniciar sesión con credenciales',
    sign_up_with: 'Registrarse con',
    generic_error: 'Se produjo un error. Por favor, inténtelo de nuevo más tarde.',
    i_agree_with_the: ' Estoy de acuerdo con los ',
    we_have_sent_you_an_email: ' Te hemos enviado un correo electrónico :-)',
    email: 'Correo electrónico',
    password: 'Contraseña',
    lost_your_password: '¿Perdiste tu contraseña?',
    recovery_here: 'recuperarla aquí',
    sign_in: 'Iniciar sesión',
    forgot_password: '¿Olvidaste tu contraseña?',
    create_new_account: 'Crear nueva cuenta',
    terms_of_service: 'Términos de Servicio',
    link_privacy: 'Política de Privacidad',
    term_conditions_text: 'Al registrarse o iniciar sesión con uno de los servicios mencionados, usted acepta nuestros {link_terms} y reconoce nuestra {link_privacy}, que describe cómo manejamos sus datos personales.',
    about_us: 'Sobre nosotros',
    blog: 'Blog',
    mit_license: 'Licencia MIT',
    docs: 'Documentos',
    loading: 'Cargando',
    dashboard: 'Panel de control',
    add_new_entry: 'Agregar nueva entrada',
    add_new_model: 'Agregar nuevo modelo',
    my_entries: 'Mis entradas',
    search_entries: 'Buscar entradas',
    payee_list: 'Lista de beneficiarios',
    planned_entries: 'Entradas planificadas',
    budgets: 'Presupuestos',
    settings: 'Configuraciones',
    logout: 'Cerrar sesión',
    expenses: 'Gastos',
    incoming: 'Entradas',
    transfer: 'Transferencia',
    debit: 'Débito',
    choose_one_of_currently_tags: 'Elige una de las etiquetas actuales',
    choose_a_wallet_to_transfer_to: 'Elige una billetera a la que transferir',
    choose_wallet_account: 'Elige cuenta de billetera',
    choose_wallet_to_transfer: 'Elige una billetera a la que transferir',
    payment_confirg: 'Confirmación de pago',
    choose_an_option: 'Elige una opción',
    choose_a_model: 'Elige un modelo',
    out_of_wallet: 'Fuera de la billetera',
    insert_new_tag_name: 'Inserta un nuevo nombre de ETIQUETA',
    add_here_your_note: 'Añade tu nota aquí',
    insert_new_entry: 'Insertar nueva entrada',
    show_planned_entries: 'Mostrar entradas planificadas',
    show_more: 'Mostrar más',
    edit: 'Editar',
    restore: 'Restaurar',
    reasume_of_all_entries: 'Resumen de todas las entradas',
    list_of_all_payees: 'Lista de todos los debedores',
    forget_debit: 'Olvidar débito',
    date_start: 'Fecha de inicio',
    debit_name: 'Nombre del débito',
    date_end: 'Fecha de finalización',
    choose_frequency: 'Elige una frecuencia',
    choose_method: 'Elige un método',
    insert_new_planned_entry: 'Insertar nueva entrada planificada',
    create_new_budget: 'Crear nuevo presupuesto',
    budget_name: 'Nombre del presupuesto',
    period: 'Periodo',
    budget: 'Presupuesto',
    account: 'Cuenta',
    category: 'Categoría',
    label: 'Etiqueta',
    labels: 'Etiquetas',
    entry_type: 'Tipo de entrada',
    enable_email_notification: 'Habilitar notificación por correo electrónico',
    save_budget: 'Guardar presupuesto',
    add_new_wallet: 'Agregar nueva billetera',
    show_archived: 'Mostrar archivados',
    wallet_settings: 'Configuraciones de la billetera',
    category_settings: 'Configuraciones de la categoría',
    add_new_category: 'Agregar nueva categoría',
    category_name: 'Nombre de la categoría',
    exclude_from_stats: 'Excluir de las estadísticas',
    save_changes: 'Guardar cambios',
    archive_label: 'Archivar etiqueta',
    add_model_entry: 'Agregar nueva entrada de modelo',
    model_settings: 'Configuraciones del modelo',
    currency_settings: 'Configuraciones de moneda',
    wallets: 'Billeteras',
    models: 'Modelos',
    categories: 'Categorías',
    currency: 'Moneda',
    profile: 'Perfil',
    assistance: 'Asistencia',
    my_entry: 'Mi entrada',
    planned_entry: 'Entrada planificada',
    add: 'Agregar',
    model: 'Modelo',
    wallet: 'Billetera',
    payee: 'Beneficiario',
    frequency: 'Frecuencia',
    method: 'Método',
    amount: 'Monto',
    date: 'Fecha',
    tag: 'Etiqueta',
    tags: 'Etiquetas',
    note: 'Nota',
    save: 'Guardar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    confirm: 'Confirmar',
    delete: 'Eliminar',
    show: 'Mostrar',
    hide: 'Ocultar',
    archive: 'Archivar',
    unarchive: 'Desarchivar',
    search: 'Buscar',
    show_details: 'Mostrar detalles',
    bounce_rate: 'Tasa de rebote',
    session_duration: 'Duración de la sesión',
    amount_before: 'Monto anterior',
    send_a_request: 'Enviar una solicitud',
    set_your_default_currency: 'Configura tu moneda predeterminada',
    change_order: 'Cambiar orden',
    add_new_workspace: 'Agregar nuevo espacio de trabajo',
    set_up_your_workspaces: 'Configura tus espacios de trabajo',
    choose_parent_category: 'Elige una categoría principal',
    label_color: 'Color de la etiqueta',
    choose_currency: 'Elige una moneda',
    choose_wallet_type: 'Elige un tipo de billetera',
    payment_deadline: 'Fecha límite de pago',
    credit_card_installment: 'Cuota de tarjeta de crédito',
    wallet_color: 'Color de la billetera',
    workspace_settings: 'Configuraciones del espacio de trabajo',
    default_currency: 'Moneda predeterminada',
    default_payment_type: 'Tipo de pago predeterminado',
    share_workspace_with: 'Compartir espacio de trabajo con',
    share: 'Compartir',
    remove: 'Eliminar',
    workspace_shared_with: 'Espacio de trabajo compartido con',
    choose_a_category: 'Elige una categoría',
    payment_confirm: 'Confirmación de pago',
    my_planned: 'Mi planificado',
    my_health: 'Salud económica',
    workspace: 'Workspace',
    entry_saved: 'Entrada guardada exitosamente',
    could_not_log_in: 'No puedo conectarme',
    send_my_password: 'Enviar mi contraseña',
    recovery_your_password: 'Recuperar tu contraseña',
    name: 'Nombre',
    confirm_password: 'Conferma password',
    privacy_policy: 'Privacy & Policy',
    create_account: 'Crear una cuenta nueva',
    click_here: 'Clicca qui',
    save_template: 'Salva modello',
    set_new_password: 'Establecer nueva contraseña',
    account_created: 'Cuenta creada exitosamente, verifica tu correo electrónico para confirmarla y activar tu cuenta',
    search_by_text: 'Search by text',
    is_planned: 'Is planned',
    no: 'NO',
    yes: 'YES',
    date_interval: 'Date interval',
    type_of_transaction: 'Type of transaction',
    choose_period: 'Elige un período',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    yearly: 'Anual',
    one_shot: 'Única vez',
    recursive: 'Recursivo',
    or_nsert_new_tag_name: 'O inserta un nuevo nombre de etiqueta',
    no_budgets_found: 'No se encontraron presupuestos',
    list_of_entries_of: 'Lista de entradas de',
    no_payees_found: 'No se encontraron debedores',
    list_of_all_budgets: 'Lista de todos los presupuestos',
    list_of_all_planned_entries: 'Lista de todas las entradas planificadas',
    set_your_default_currecny: 'Set your default currency',
    update: 'Actualizar',
    stats: 'Estadísticas',
    payment_types: 'Tipos de pago',
    create_new_debit: 'Crear nuevo débito',
    months: {
        jan: 'Ene',
        january: 'Enero',
        feb: 'Feb',
        february: 'Febrero',
        mar: 'Mar',
        march: 'Marzo',
        apr: 'Abr',
        april: 'Abril',
        may: 'May',
        jun: 'Jun',
        june: 'Junio',
        jul: 'Jul',
        july: 'Julio',
        aug: 'Ago',
        august: 'Agosto',
        sep: 'Sep',
        september: 'Septiembre',
        oct: 'Oct',
        october: 'Octubre',
        nov: 'Nov',
        november: 'Noviembre',
        dec: 'Dic',
        december: 'Diciembre',
    },
    closing_account_statement: 'Closing account statement',
    account_payment: 'Pago de cuenta',
    start_date: 'Fecha de inicio',
    end_date: 'Fecha de finalización',
    show_entries: 'Mostrar entradas',
    wallet_name: 'Nombre de la billetera',
    balance_wallet: 'Saldo de la billetera',
    wallet_type: 'Tipo de billetera',
    next_execution_time: 'Próxima dia de ejecución',
    end_time: 'Dia de finalización',
    no_data_found: 'No se encontraron datos',
    average_incoming: 'Ingresos promedio',
    average_expenses: 'Gastos promedio',
    average_savings: 'Ahorros promedio',
    your_planned_expenses: 'Gastos planificados',
    list_of_voices: 'Lista de voces',
    no_voices_found: 'No se encontraron voces',
    workspace_whared_with: 'Espacio de trabajo compartido con',
    user_not_found: 'Usuario no encontrado',
    save_as_model: 'Guardar como modelo',
    write_temlate_name: 'Escribe el nombre del modelo',
    end_of_month: 'Fin de mes',
    ticket_value: 'Valor del voucher',
    spend: 'gastado',
    remaining: 'restante',
    planned: 'planificado',
    no_entries_found: 'No se encontraron entradas',
    credit_card_installement: 'Cuota de tarjeta de crédito',
    choose_planned_type: 'Elige un tipo planificado',
    list_of_all_credit_cards: 'Lista de todas las tarjetas de crédito',
    details: 'Detalles',
    back_to_login: 'Volver a iniciar sesión',
    workspace_name: 'Nombre del espacio de trabajo',
    first_wallet_name: 'Nombre de la primera billetera',
    wallet_amount: 'Monto de la billetera',
    setup_account: 'Configurar cuenta',
    donate: 'Donar',
    faq: 'FAQ',
}
</script>