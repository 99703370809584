import { defineStore } from 'pinia';
const STORE_NAME = 'refresh';
export const useRefreshStore = defineStore(STORE_NAME, {
    state: () => ({
        state: false
    }),
    actions: {
        set(value) {
            this.state = value;
        },
        get() {
            return this.state;
        },
    },
    persist: false,
});
