import { defineStore } from 'pinia';
const STORE_NAME = 'workspace';
export const useWorkspaceStore = defineStore(STORE_NAME, {
    state: () => ({
        workspace: {}
    }),
    actions: {
        set(workspace) {
            this.workspace = workspace;
        },
        get() {
            return this.workspace;
        },
        resetState() {
            this.$reset();
        }
    },
    persist: true
});
