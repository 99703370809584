import ApiService from './api.service';
class WorkspaceService extends ApiService {
    async get(id) {
        const response = await this.instance.get(`/api/workspace/${id}`);
        return response.data;
    }
    async list() {
        const response = await this.instance.get(`/api/workspace/list`);
        return response.data;
    }
    async listByUser() {
        const response = await this.instance.get(`/api/workspace/by-user/list`);
        return response.data;
    }
    async add(data) {
        const response = await this.instance.post(`/api/workspace/create`, data);
        return response.data;
    }
    async activeWorkspace(id) {
        const response = await this.instance.patch(`/api/workspace/activate/${id}`);
        return response.data;
    }
    async update(workspace) {
        const response = await this.instance.put(`/api/workspace/update/${workspace.id}`, workspace.data);
        return response.data;
    }
    async delete(id) {
        const response = await this.instance.delete(`/api/workspace/delete/${id}`);
        return response.data;
    }
}
export default WorkspaceService;
