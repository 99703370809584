<script>
export default {
    food_drinks: 'food & drinks',
    shopping: 'shopping',
    home: 'home',
    transport: 'transport',
    vehicles: 'vehicles',
    entertainment: 'entertainment',
    communication_pc: 'communication pc',
    financial_expenses: 'financial expenses',
    income: 'income',
    other: 'other',
    bars_venues: 'bars & venues',
    restaurants_fast_food: 'restaurants & fast-food',
    groceries: 'groceries',
    clothing_shoes: 'clothing & shoes',
    pets: 'pets',
    beauty_wellness: 'beauty & wellness',
    stationery_tools: 'stationery & tools',
    home_garden: 'home & garden',
    electronics_accessories: 'electronics, accessories',
    pharmacy: 'pharmacy',
    children: 'children',
    jewelry_accessories: 'jewelry & accessories',
    gift_expenses: 'gift expenses',
    family_help: 'family help',
    leisure: 'leisure',
    rent: 'rent',
    property_insurance: 'property insurance',
    condominium: 'condominium',
    energy_utilities: 'energy & utilities',
    maintenance: 'maintenance',
    mortgage: 'mortgage',
    taxi: 'taxi',
    public_transport: 'public transport',
    travel: 'travel',
    business_trips: 'business trips',
    vehicle_insurance: 'vehicle insurance',
    fuel: 'fuel',
    vehicle_maintenance: 'vehicle maintenance',
    rentals: 'rentals',
    parking: 'parking',
    tolls: 'tolls',
    alcohol: 'alcohol',
    tobacco: 'tobacco',
    culture_sports_events: 'culture, sports events',
    donations_charity: 'donations, charity',
    events: 'events',
    wedding: 'wedding',
    education_development: 'education, development',
    hobbies: 'hobbies',
    books_audio_subscriptions: 'books, audio, subscriptions',
    lotteries_gambling: 'lotteries & gambling',
    health: 'health',
    sports_fitness: 'sports, fitness',
    tv_streaming: 'tv streaming',
    holidays: 'holidays',
    hotels: 'hotels',
    internet: 'internet',
    postal_services: 'postal services',
    software_apps_games: 'software, apps & games',
    phone: 'phone',
    insurance: 'insurance',
    consulting: 'consulting',
    fines: 'fines',
    loans_interest: 'loans & interest',
    taxes: 'taxes',
    taxes_fees: 'taxes, fees',
    real_estate: 'real estate',
    movable_goods: 'movable goods',
    financial_investments: 'financial investments',
    collections: 'collections',
    savings: 'savings',
    checks: 'checks',
    coupons: 'coupons',
    family_allowance: 'family allowance',
    rental_income: 'rental income',
    interest_dividends: 'interest, dividends',
    loans_rentals: 'loans, rentals',
    dues_grants: 'dues, grants',
    gifts: 'gifts',
    refunds: 'refunds',
    salary: 'salary',
    sales: 'sales',
    transfer: 'transfer',
    cash: 'cash',
    credit_card: 'credit card',
    debit_card: 'debit card',
    transfer_bank: 'transfer',
    check: 'check',
    us_dollar: 'US Dollar',
    euro: 'Euro',
    british_pound: 'British Pound',
    japanese_yen: 'Japanese Yen',
    swiss_franc: 'Swiss Franc',
    canadian_dollar: 'Canadian Dollar',
    australian_dollar: 'Australian Dollar',
    new_zealand_dollar: 'New Zealand Dollar',
    swedish_krona: 'Swedish Krona',
    norwegian_krone: 'Norwegian Krone',
    danish_krone: 'Danish Krone',
    singapore_dollar: 'Singapore Dollar',
    hong_kong_dollar: 'Hong Kong Dollar',
    chinese_yuan: 'Chinese Yuan',
    russian_ruble: 'Russian Ruble',
    indian_rupee: 'Indian Rupee',
    indonesian_rupiah: 'Indonesian Rupiah',
    brazilian_real: 'Brazilian Real',
    mexican_peso: 'Mexican Peso',
    argentine_peso: 'Argentine peso',
    pastry_shop: 'pastry shop',
    pub: 'pub',
    transportation: 'transporte',
    investments: 'inversiones',
    food_beverages: 'comida y bebidas',
    computer_communication: 'comunicación pc',
    months: {
        jan: 'Jan',
        january: 'January',
        feb: 'Feb',
        february: 'February',
        mar: 'Mar',
        march: 'March',
        apr: 'Apr',
        april: 'April',
        may: 'May',
        jun: 'Jun',
        june: 'June',
        jul: 'Jul',
        july: 'July',
        aug: 'Aug',
        august: 'August',
        sep: 'Sep',
        september: 'September',
        oct: 'Oct',
        october: 'October',
        nov: 'Nov',
        november: 'November',
        dec: 'Dec',
        december: 'December',
    },
    bank_accounts: {
        bank: 'Bank',
        cache: 'Cash',
        "credit-card": 'Credit Card',
        "credit-card-revolving": 'Credit Card Revolving',
        investment: 'Investment',
        loan: 'Loan',
        other: 'Other',
        "prepaid-card": 'Prepaid Card',
        voucher: 'Voucher'
    },
    stats_by_category: 'Stats by category',
    stats_by_tags: 'Stats by tags',
}
</script>
