import { defineStore } from 'pinia';
const STORE_NAME = 'app-settings';
export const useAppSettings = defineStore(STORE_NAME, {
    state: () => ({
        settings: {}
    }),
    actions: {
        get() {
            return this.settings;
        },
        getEye() {
            return this.settings.eye_settings;
        },
        setEye(value) {
            this.settings.eye_settings = value;
        },
        getCurrencyId() {
            return this.settings.currency.id;
        },
        getPaymentType() {
            return this.settings.user.workspace_settings.data.payment_type_id;
        },
        getUser() {
            return this.settings.user;
        },
        resetState() {
            this.$reset();
        },
        getWorkspace() {
            return this.settings.current_ws;
        }
    },
    persist: true
});
